import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { calculateRating } from '../components/helper';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Breadcrumb from '../components/services/breadcrumb';
import AnouncementBar from '../components/home/announcement-bar';
import ProfileReviews from '../components/profile-reviews';
import CloseIcon from '../images/icon-close.inline.svg';
import '../assets/styles/templates/panel-beater-profile.scss';

const PanelBeaterProfile = ({ data }) => {
  const [imagePopup, setImagePopup] = useState(false);
  const [openAccordion, setOpenAccordion] = useState(false);
  const [image, setImage] = useState(false);
  const innerAccordion = useRef(null);
  const { pblist } = data;
  const {
    badges,
    breadcrumbs,
    businessHours,
    contactless,
    coreServices,
    otherServices,
    photoGallery,
    qualifications,
    reviewOverview,
    reviews,
    seo,
    licenses,
	structuredData
  } = pblist;

  return (
    <Layout wrapperClass="panel-beater-profile" showSiteIndex={true} hideHeaderFooter={true}>
      <SEO
        title={seo.title}
        description={seo.title}
        canonical={seo.canonical}
        stucturedData={structuredData}
        ogTitle={seo.og.title}
        ogDescription={seo.og.description}
        ogImage={seo.og.image}
        ogType={seo.og.type}
        ogUrl={seo.og.url}
      />
      <AnouncementBar />
      <div className="container">
        <div className="section title-section">
          <div className="image-wrapper">
            {pblist.photoLink && (
              <img src={pblist.photoLink.url} alt={pblist.name} />
            )}
          </div>
          <div className="text-wrapper">
            <h1>{pblist.name}</h1>
            <div className="address">{pblist.address}</div>
            {reviewOverview && (
              <div className="reviews-overview-wrapper">
                <div className="rating-stars-container">
                  <span className="rating-stars rating-stars-off">★★★★★</span>
                  <span className="rating-stars rating-stars-on" style={{ width: `${calculateRating(reviewOverview.reviewAverage)}%` }}>★★★★★</span>
                </div>
                <span className="review-total">{reviewOverview.count} reviews</span>
              </div>
            )}
            <Link className="btn" to="/quote">Compare Quotes</Link>
          </div>
        </div>

        {breadcrumbs && (
          <Breadcrumb breadcrumbs={breadcrumbs} />
        )}
      </div>
      <div className="container main-content">
        <div className="row">
          <div className="content col">
            <div className="section">
              <div className="about-wrapper">
                <h2>About</h2>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: pblist.about,
                  }}
                />
              </div>
              {photoGallery && (
                <div className="photo-gallery-wrapper">
                  <h3>Photo Gallery</h3>
                  <div className="photo-gallery-row">
                    {photoGallery.map((photo) => {
                      if (photo.photoLink.url) {
                        return (
                          <div
                            className="image-tile"
                            key={photo.photoLink.url}
                            onClick={(e) => {
                              e.preventDefault();
                              setImage(photo.photoLink.url);
                              setImagePopup(!imagePopup);
                            }}
                          >
                            <span>+</span>
                            <img src={photo.photoLink.url} alt={`${pblist.name} Photos`} />
                          </div>
                        )
                      }

                      return null;
                    })}
                  </div>
                </div>
              )}
            </div>
            <div className="section">
              <h2>Shop Services</h2>
              <div className="services-wrapper">
                <div className="services-col">
                  {coreServices && (
                    <ul>
                      {coreServices.map((service) => (
                        <li key={service.title}>
                          <a href={service.url}>
                            {service.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="services-col">
                  {otherServices && (
                    <ul>
                      {otherServices.map((service) => (
                        <li key={service.title}>
                          {service.title}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            {pblist.whatItTakes && (
              <div className="section">
                <div className={`accordion ${openAccordion ? 'open' : 'closed'}`}>
                  <div className="accordion-title"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenAccordion(!openAccordion);
                    }}>
                    {whatItTakes.title}
                  </div>
                  <div
                    className="accordion-body"
                    style={{ height: (innerAccordion.current) ? innerAccordion.current.offsetHeight : '0px' }}
                  >
                    <div
                      className="accordion-body-inner"
                      ref={innerAccordion}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: whatItTakes.text,
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {reviews && (
              <div className="section">
                <h2>Reviews</h2>
                <ProfileReviews overview={reviewOverview} data={reviews.reviewDate} reviews={reviews} />
              </div>
            )}
          </div>
          <div className="sidebar col">
            <div className="section">
              <h3>Qualifications</h3>
              {qualifications && (
                <ul className="icon-list">
                  {qualifications.map((qualification) => (
                    <li key={qualification.text}>
                      {qualification.photoLink.url && (
                        <img src={qualification.photoLink.url} alt={qualification.text} height="20" />
                      )}
                      {qualification.text}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div className="section">
              <h3>Badges</h3>
              {badges && (
                <ul className="icon-list">
                  {badges.map((badge) => (
                    <li key={badge.text}>
                      {badge.photoLink.url && (
                        <img src={badge.photoLink.url} alt={badge.text} height="20" />
                      )}
                      {badge.text}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {contactless && (
              <div className="section">
                <h3>Contactless Services</h3>
                <ul className="contactless-list">
                  {contactless.map((item) => (
                    <li key={item.title}>
                      <a href={item.url}>
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className="section">
              {pblist.businessLogo && (
                <div className="business-logo-wrapper">
                  {pblist.businessLogo.photoLink.url && (
                    <img src={pblist.businessLogo.photoLink.url} alt={`${pblist.name} Logo`} />
                  )}
                </div>
              )}
              <h3>Business Details</h3>
              <ul className="icon-list">
                {pblist.address && (
                  <li>
                    <span className="icon-map-marker" />
                    {pblist.address}
                  </li>
                )}
                {pblist.abn && (
                  <li>
                    <span className="icon-building-o" />
                        Business Number: {pblist.abn}
                  </li>
                )}
                {licenses && (
                  licenses.map((license) => (
                    <li key={license.number}>
                      <span className="icon-chain" />
                      {license.state}: {license.number}
                    </li>
                  ))
                )}
                <li>
                  <span className="icon-clock-o" />
                  {businessHours && (
                    <table>
                      <tbody>
                        <tr>
                          <td>Monday</td>
                          <td>{businessHours.monday === null ? 'closed' : businessHours.monday}</td>
                        </tr>
                        <tr>
                          <td>Tuesday</td>
                          <td>{businessHours.tuesday === null ? 'closed' : businessHours.tuesday}</td>
                        </tr>
                        <tr>
                          <td>Wednesday</td>
                          <td>{businessHours.wednesday === null ? 'closed' : businessHours.wednesday}</td>
                        </tr>
                        <tr>
                          <td>Thursday</td>
                          <td>{businessHours.thursday === null ? 'closed' : businessHours.thursday}</td>
                        </tr>
                        <tr>
                          <td>Friday</td>
                          <td>{businessHours.friday === null ? 'closed' : businessHours.friday}</td>
                        </tr>
                        <tr>
                          <td>Saturday</td>
                          <td>{businessHours.saturday === null ? 'closed' : businessHours.saturday}</td>
                        </tr>
                        <tr>
                          <td>Sunday</td>
                          <td>{businessHours.sunday === null ? 'closed' : businessHours.sunday}</td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </li>
              </ul>
            </div>
            <div className="section">
              <div className="quote-cta">
                <h2>
                  Are you ready?
                  Lets DingGo!
                </h2>
                <Link to="/quote" className="btn">
                  Get started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`popup-overlay ${imagePopup ? 'active' : ''}`}
        onClick={(e) => {
          e.preventDefault();
          setImagePopup(!imagePopup);
        }}
      />
      <div className={`popup-wrapper ${imagePopup ? 'active' : ''}`}>
        <CloseIcon className="close-icon"
          onClick={(e) => {
            e.preventDefault();
            setImagePopup(!imagePopup);
          }} />
        {image && (
          <img src={image} alt={`${pblist.name} Photos`} />
        )}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query PbQuery($slug: String!) {
    pblist(slug: { eq: $slug }) {
      url
      title
      structuredData
      slug
      photoLink {
        url
      }
      photoGallery {
        photoLink {
          url
        }
      }
      otherServices {
        title
      }
      name
      businessLogo {
        photoLink {
          url
        }
      }
      address
      about
      abn
      badges {
        photoLink {
          url
        }
        text
      }
      breadcrumbs {
        text
        url
      }
      licenses {
        number
        state
      }
      businessHours {
        friday
        monday
        saturday
        sunday
        thursday
        tuesday
        wednesday
      }
      contactless {
        title
        url
      }
      coreServices {
        title
        url
      }
      licenses {
        number
        state
      }
      qualifications {
        photoLink {
          url
        }
        text
      }
      reviewOverview {
        valueForMoneyReviewAverage
        qualityOfWorkReviewAverage
        communicationReviewAverage
        repairTimeReviewAverage
        trustworthinessReviewAverage
        reviewAverage
        count
      }
      reviews {
        photoLink {
          url
        }
        id
        fullName
        ratingOverall
        reviewDate
        reviewText
      }
      seo {
        canonical
        description
        heading
        og {
          description
          image
          title
          type
          url
        }
        title
      }
    }
  }
`;


PanelBeaterProfile.propTypes = {
  data: PropTypes.shape().isRequired,
  pageContext: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default PanelBeaterProfile;
