import React, { useState, useRef } from 'react';
import { calculateRating, roundNumber } from './helper';
import '../assets/styles/components/profile-reviews.scss';

const ProfileReviews = ({ overview, data, reviews }) => {
  const [openAccordion, setOpenAccordion] = useState(false);
  const [reviewObj, setReviewObj] = useState(reviews);
  const innerAccordion = useRef(null);

  const handleInputChange = (event) => {
    const { value } = event.target;
    const reviewClone = [].concat(reviewObj);

    if (value === 'newest') {
      reviewClone.sort((a, b) => {
        const aDate = new Date(a.reviewDate).getTime();
        const bDate = new Date(b.reviewDate).getTime();
        return bDate - aDate
      });
    }

    if (value === 'oldest') {
      reviewClone.sort((a, b) => {
        const aDate = new Date(a.reviewDate).getTime();
        const bDate = new Date(b.reviewDate).getTime();
        return aDate - bDate
      });
    }

    if (value === 'highest') {
      reviewClone.sort((a, b) => {
        return b.reviewAverage - a.reviewAverage
      });
    }

    if (value === 'lowest') {
      reviewClone.sort((a, b) => {
        return a.reviewAverage - b.reviewAverage
      });
    }

    setReviewObj(reviewClone);
  };

  if (reviewObj.length === 0) {
    return (
      <p>No reviews</p>
    );
  }

  const reviewTile = (review) => {
    return (
      <div className="review-item" key={review.id}>
        <div className="review-image">
          {review.photoLink && (
            <img src={review.photoLink.url} />
          )}
        </div>
        <div className="review-details">
          <h2>{review.fullName}</h2>
          <div className="reviews-overview-wrapper">
            <div className="rating-stars-container">
              <span className="rating-stars rating-stars-off">★★★★★</span>
              <span className="rating-stars rating-stars-on" style={{ width: `${calculateRating(review.ratingOverall)}%` }}>★★★★★</span>
            </div>
            <span className="review-total">{review.reviewDate}</span>
          </div>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: review.reviewText,
            }}
          />
        </div>
      </div>
    )
  };

  return (
    <div className="reviews-wrapper">
      <div className="review-header">
        <div className="review-overview">
          <span className="review-average">{roundNumber(overview.reviewAverage, 1)}</span>
          <div className="rating-stars-container">
            <span className="rating-stars rating-stars-off">★★★★★</span>
            <span className="rating-stars rating-stars-on" style={{ width: `${calculateRating(overview.reviewAverage)}%` }}>★★★★★</span>
          </div>
          <span className="review-total">{overview.count} reviews</span>
        </div>
        <div className="review-breakdown">
          {overview.valueForMoneyReviewAverage &&
            <div className="breakdown-item">
              <span>
                Value for money
                <strong>
                  {roundNumber(overview.valueForMoneyReviewAverage, 1)}
                </strong>
              </span>
              <div className="breakdown-bar">
                <span className="breakdown-bar-inner" style={{ width: `${calculateRating(overview.valueForMoneyReviewAverage)}%` }} />
              </div>
            </div>
          }
          {overview.qualityOfWorkReviewAverage &&
            <div className="breakdown-item">
              <span>
                Quality of work
                <strong>
                  {roundNumber(overview.qualityOfWorkReviewAverage, 1)}
                </strong>
              </span>
              <div className="breakdown-bar">
                <span className="breakdown-bar-inner" style={{ width: `${calculateRating(overview.qualityOfWorkReviewAverage)}%` }} />
              </div>
            </div>
          }
          {overview.communicationReviewAverage &&
            <div className="breakdown-item">
              <span>
                Communication
                <strong>
                  {roundNumber(overview.communicationReviewAverage, 1)}
                </strong>
              </span>
              <div className="breakdown-bar">
                <span className="breakdown-bar-inner" style={{ width: `${calculateRating(overview.communicationReviewAverage)}%` }} />
              </div>
            </div>
          }
          {overview.trustworthinessReviewAverage &&
            <div className="breakdown-item">
              <span>
                Trustworthiness
                <strong>
                  {roundNumber(overview.trustworthinessReviewAverage, 1)}
                </strong>
              </span>
              <div className="breakdown-bar">
                <span className="breakdown-bar-inner" style={{ width: `${calculateRating(overview.trustworthinessReviewAverage)}%` }} />
              </div>
            </div>
          }
          {overview.repairTimeReviewAverage &&
            <div className="breakdown-item">
              <span>
                Repair time /convenience
                <strong>
                  {roundNumber(overview.repairTimeReviewAverage, 1)}
                </strong>
              </span>
              <div className="breakdown-bar">
                <span className="breakdown-bar-inner" style={{ width: `${calculateRating(overview.repairTimeReviewAverage)}%` }} />
              </div>
            </div>
          }
        </div>
      </div>
      <div className="reviews-filter">
        <div className="form-group">
          <select className="form-control" defaultValue="" onChange={(e) => { handleInputChange(e); }}>
            <option value="" disabled>Sort by</option>
            <option value="newest" name="newest">Date (Newest First)</option>
            <option value="oldest" name="oldest">Date (Oldest First)</option>
            <option value="highest" name="highest">Highest Rating</option>
            <option value="lowest" name="lowest">Lowest Rating</option>
          </select>
        </div>
      </div>
      <div className="reviews">
        {reviewObj.map((review, idx) => {
          if (idx < 5) {
            return reviewTile(review);
          }

          return null;
        })}

        <div
          className="extra-reviews"
          style={{ height: (openAccordion) ? innerAccordion.current.offsetHeight : '0px' }}
        >
          <div
            className="inner-extra-reviews"
            ref={innerAccordion}
          >
            {reviewObj.map((review, idx) => {
              if (idx >= 5) {
                return reviewTile(review);
              }

              return null;
            })}
          </div>
        </div>

        {reviewObj.length >= 5 && (
          <button
            className="btn extra-review-cta"
            onClick={(e) => {
              e.preventDefault();
              setOpenAccordion(!openAccordion);
            }}>
            See {openAccordion ? 'less' : 'more'}
          </button>
        )}
      </div>
    </div>
  );
};

export default ProfileReviews;
